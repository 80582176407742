import React from "react";

export default function Loader() {
  return (
    <div className="px-3">
      <div class="animate-pulse flex justify-center items-center flex-col gap-3">
        <div className=" animate-pulse block w-full rounded-md border-0  bg-gray-200 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 py-7" />
        <div className=" mt-4 animate-pulse flex justify-center items-center w-1/2 rounded-md border-0  bg-gray-200 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 py-10 mb-4" />
        <div className=" animate-pulse block w-1/2 rounded-md border-0  bg-gray-200 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 py-5 mb-4" />
      </div>
    </div>
  );
}
