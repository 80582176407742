import React, { useEffect, useState } from "react";
import { StatusScreen } from "@mercadopago/sdk-react";
import PlaceholderStatus from "./PlaceholderStatus";
export default function StatusPage({ paymentId }) {
  const [loader, setLoader] = useState(true);
  const initialization = {
    paymentId, // id de pago para mostrar
  };

  useEffect(() => {
    setLoader(false);
  }, [loader]);

  const onError = async (error) => {
    console.log(error);
  };
  const onReady = async () => {
    setLoader(false);
    console.log("Listo XX");
  };
  const customization = {
    visual: {
      showExternalReference: true,
    },
  };

  return (
    <div>
      <div className="mt-4">
        {loader ? (
          <PlaceholderStatus />
        ) : (
          <StatusScreen
            initialization={initialization}
            onReady={onReady}
            onError={onError}
            customization={customization}
          />
        )}
      </div>
    </div>
  );
}
